import { api } from '@/api';
import { env } from '@/utils';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

let echo = null;

export function useWebsockets(): Echo<'reverb'> {
  if (echo) {
    return echo
  }

  echo = new Echo({
    broadcaster: 'reverb',
    key: env('SOCKET_KEY'),
    wsHost: env('SOCKET_HOST', window.location.hostname),
    wsPort: env('SOCKET_PORT', 6001),
    wssPort: env('SOCKET_PORT', 6001),
    forceTLS: env('SOCKET_SCHEME', 'https') === 'https',
    disableStats: true,
    cluster: env('SOCKET_CLUSTER', 'eu'),
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel) => {
      return {
        authorize: async (socketId, callback) => {
          try {
            const response = await api.post(env('SOCKET_AUTH_ENDPOINT'), {
              socket_id: socketId,
              channel_name: channel.name,
            });
            callback(null, response.data);
          } catch (error) {
            callback(error);
          }
        },
      };
    },
  });

  return echo;
}
