import { config } from '@/utils/Env';

const types = {
  VITE_APP_TITLE: { type: 'string' },
  VITE_APP_API_URL: { type: 'string' },
  VITE_APP_NODE_ENV: { type: 'string', default: 'production' },
  VITE_APP_DEV: { type: 'boolean' },
  VITE_APP_CONFIG_PREFETCH_ENABLED: { type: 'number' },
  VITE_APP_I18N_LOCALE: { type: 'string' },
  VITE_APP_I18N_FALLBACK_LOCALE: { type: 'string' },
  VITE_APP_I18N_LOCALIZED_LINKS: { type: 'boolean' },
  VITE_APP_PASSPORT_CLIENT_ID: { type: 'number' },
  VITE_APP_AUTH: { type: 'string' },
  VITE_APP_KEYCLOAK_URL: { type: 'string' },
  VITE_APP_KEYCLOAK_CLIENT_ID: { type: 'string' },
  VITE_APP_KEYCLOAK_REALM: { type: 'string' },
  VITE_APP_SENTRY_PUBLIC_DSN: { type: 'string', optional: true, default: null },
  VITE_APP_SENTRY_ENVIRONMENT: {
    type: 'string',
    optional: true,
    default: null,
  },
  VITE_APP_VERSION: { type: 'string', optional: true, default: '0.1.0' },
  VITE_APP_SOCKET_PORT: { type: 'number' },
  VITE_APP_SOCKET_HOST: { type: 'string' },
  VITE_APP_SOCKET_KEY: { type: 'string' },
  VITE_APP_SOCKET_CLUSTER: { type: 'string', default: 'eu' },
  VITE_APP_SOCKET_AUTH_ENDPOINT: { type: 'string' },
  VITE_APP_SOCKET_SCHEME: { type: 'string' },
};

window.typedEnv = config(types as Record<string, ArgInfo>);
