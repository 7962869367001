import { RouteHelper } from '@/router/RouteHelper';
import { SidebarLayout } from '@/components/layouts';
import { BrandPagekit } from '@/components/icons/tablericons';
import appOptions from '@/config/app.options';
import { hasProjectMembershipGuard } from '@/router/guards/hasProjectMembershipGuard';
import { InboxFull, ListIcon } from '@/components/icons/flowbite';
import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/cms',
    name: 'cms.index',
    redirect: () => {
      return { name: 'cms.articles' };
    },
    beforeEnter: [hasProjectMembershipGuard(appOptions.Projects.CMS)],
    meta: {
      layout: SidebarLayout,
      icon: BrandPagekit,
      roles: appOptions.adminRoleNames,
    },
    children: [
      {
        path: '/cms/articles',
        name: 'cms.articles',
        component: () => import('~/pages/cms/articles.vue'),
        beforeEnter: [hasProjectMembershipGuard(appOptions.Projects.CMS)],
        meta: {
          layout: SidebarLayout,
          icon: InboxFull,
          roles: appOptions.adminRoleNames,
        },
      },
      {
        path: '/cms/tasks',
        name: 'cms.tasks',
        component: () => import('~/pages/cms/tasks.vue'),
        beforeEnter: [hasProjectMembershipGuard(appOptions.Projects.CMS)],
        meta: {
          layout: SidebarLayout,
          icon: ListIcon,
          roles: appOptions.adminRoleNames,
        },
      },
    ],
  },
  {
    path: '/cms/:id',
    name: 'cms.translation',
    component: () => import('~/pages/cms/[id].vue'),
    props: ({ params }) => ({
      id: RouteHelper.convertParam(params.id, 'number'),
    }),
    beforeEnter: [hasProjectMembershipGuard(appOptions.Projects.CMS)],
    meta: {
      hidden: true,
      layout: SidebarLayout,
      roles: appOptions.adminRoleNames,
    },
  },
];

export default routes;
