import { defineAsyncComponent } from 'vue';
export const SolidAtSymbol = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidAtSymbol.vue')
);

export const SolidCalendar = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidCalendar.vue')
);

export const SolidCheck = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidCheck.vue')
);

export const SolidCheckCircle = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidCheckCircle.vue')
);

export const SolidChevronDown = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidChevronDown.vue')
);

export const SolidChevronUp = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidChevronUp.vue')
);

export const SolidExclamation = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidExclamation.vue')
);

export const SolidFilter = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidFilter.vue')
);

export const SolidHashtag = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidHashtag.vue')
);

export const SolidInformationCircle = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidInformationCircle.vue')
);

export const SolidRocket = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidRocket.vue')
);

export const SolidSelector = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidSelector.vue')
);

export const SolidSortAscending = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidSortAscending.vue')
);

export const SolidSortDescending = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidSortDescending.vue')
);

export const SolidSwitchVertical = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidSwitchVertical.vue')
);

export const SolidUserCircle = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidUserCircle.vue')
);

export const SolidX = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidX.vue')
);

export const SolidXCircle = defineAsyncComponent(
  () => import('@/components/icons/heroicons/solid/SolidXCircle.vue')
);
